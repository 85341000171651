<!-- System: STA
    Purpose: Assign team member display modal in
            team member page to assign members to
            team leads etc         
-->
<template>
  <!-- Assign Team Member Modal -->
  <v-dialog v-model="MemberTeam" width="800" @click:outside="onHidden()">
    <v-card class="add_team_member" id="add_team_member" ref="add_team_member">
      <v-card-title class="headline white lighten-2 justify-center">
        Team Members
        <v-spacer></v-spacer>
        <v-btn icon @click="(MemberTeam = false), onHidden()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- Search Bar For Members -->
      <v-row>
        <v-col cols="12" md="6" offset-md="6" class="mt-3">
          <v-text-field label="Search Members" v-model="search"> </v-text-field>
        </v-col>
      </v-row>
      <!-- Labels -->
      <v-row class="mt-4 m-2">
        <v-col cols="12" md="4" class="mb-3 statusIndicators">
          <div
            @click="changeTeamMember('assigned')"
            class="assignedProjects mb-3"
            title="Members are working on project"
          >
            <div></div>
            <span>&nbsp;Assigned</span>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="mb-3 statusIndicators">
          <div
            @click="changeTeamMember('unassigned')"
            class="unAssignedProjects mb-3"
            title="Members are inactive and not working on project"
          >
            <div></div>
            <span>&nbsp;Unassigned</span>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="mb-3 statusIndicators">
          <div
            @click="changeTeamMember('all')"
            class="allProjects mb-3"
            title="Show All Members"
          >
            <div></div>
            <span>&nbsp;All</span>
          </div>
        </v-col>
      </v-row>
      <!-- Members Components  -->
      <v-row class="m-0 ml-4">
        <div
          :key="index"
          class="m-0 mb-3 pl-0 pr-2"
          v-for="(member, index) in unassignedTeamMember"
          v-if="showUnassignedTeamMember"
        >
          <TeamMember
            :member_data="member"
            @click.native="addToTeamMembers(member)"
          />
        </div>
        <div
          :key="index"
          class="m-0 mb-3 pl-0 pr-2"
          v-for="(member, index) in assignedTeamMember"
          v-if="showAssignedTeamMember"
        >
          <TeamMember
            :member_data="member"
            @click.native="addToTeamMembers(member)"
          />
        </div>
        <div
          :key="index"
          class="m-0 mb-3 pl-0 pr-2"
          v-for="(member, index) in sorted_members"
          v-if="showAll"
        >
          <TeamMember
            :member_data="member"
            @click.native="addToTeamMembers(member)"
          />
        </div>
        <!-- Render If No Project Found -->
        <v-row v-if="noTeamMemberFound" class="d-flex justify-center">
          <h6 class="text-center mt-5 mb-10" style="color: #88a4cf">
            No Projects Found.
          </h6>
        </v-row>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MemberTeam",
  components: {
    TeamMember: () => import("@/components/TeamMembers/TeamMember"),
  },
  data() {
    return {
      MemberTeam: false,
      teamLead: null,
      members: [],
      search: "",
      showUnassignedTeamMember: false,
      showAssignedTeamMember: false,
      showAll: true,
      url: "custom/getTeamforLeader",
    };
  },
  mounted() {
    this.$eventBus.$on("assignTeamMembers", (member) => {
      this.teamLead = member;
    });
  },
  watch: {
    teamLead: function (teamLead) {
      if (teamLead) this.getTeam(teamLead);
    },
  },
  computed: {
    sorted_members() {
      let members = [];
      let unassignedMembers = [];
      let assignedMembers = [];
      this.members.forEach((member) => {
        if (
          member.member.name &&
          member.member.name.toLowerCase().includes(this.search.toLowerCase())
        ) {
          if (member.is_assigned) {
            //checking if member is assigned
            assignedMembers.push(member); // pushing to array
          } else {
            unassignedMembers.push(member); // if unassigned pushed to another array
          }
        }
      });
      members = unassignedMembers.concat(assignedMembers);
      return members; //showing al team members
    },
    unassignedTeamMember() {
      return this.sorted_members.filter((member) => !member.is_assigned);
    },
    assignedTeamMember() {
      return this.sorted_members.filter((member) => member.is_assigned);
    },
    noTeamMemberFound() {
      return (
        !this.members.length ||
        (this.showUnassignedTeamMember && !this.unassignedTeamMember.length) ||
        (this.showAssignedTeamMember && !this.assignedTeamMember.length)
      );
    },
  },
  methods: {
    /**
     * This function is resposible for filter
     * the list of team member
     */
    changeTeamMember(filter) {
      this.showUnassignedTeamMember = false;
      this.showAssignedTeamMember = false;
      this.showAll = false;
      if (filter === "assigned") {
        this.showAssignedTeamMember = true;
      } else if (filter === "unassigned") {
        this.showUnassignedTeamMember = true;
      } else {
        this.showAll = true;
      }
    },
    /**
     * This function is resposible for clear
     * data when modal close
     */
    onHidden() {
      this.teamLead = null;
      this.members = [];
    },
    /**
     * This function is resposible for getting
     * members of that user
     */
    getTeam(teamLead) {
      let data = {
        company_id: this.$route.params.id,
        member_id: teamLead.id,
      };
      this.$store.dispatch(this.url, data).then((response) => {
        this.MemberTeam = true;
        this.$store.commit("custom/toggle_loader", false);
        let { members, team_members } = response.data;
        this.members = [
          ...team_members.map((element) => ({
            member: element,
            is_assigned: true,
          })),
          ...members.map((element) => ({
            member: element,
            is_assigned: false,
          })),
        ];
        this.sortMembers();
        this.getUniqueMembers();
      });
    },
    /**
     * This function is resposible for adding
     * members to that user team
     */
    addToTeamMembers: _.debounce(
      function (member) {
        this.search = "";
        let index = this.members.findIndex((Member) => Member === member);
        this.members[index].is_assigned = !this.members[index].is_assigned;
        this.updateTeamMember(member.member.hash_id);
      },
      300,
      { leading: true, trailing: false }
    ),
    /**
     * This function is resposible for sorting
     * of team members
     */
    sortMembers() {
      this.members.sort((a, b) => {
        let x = a.member.name.toLowerCase();
        let y = b.member.name.toLowerCase();
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
    },
    getUniqueMembers() {
      this.members = this.members.filter(
        (item, pos, ary) =>
          !pos || item.member.hash_id !== ary[pos - 1].member.hash_id
      );
    },
    /**
     * This function is resposible for adding
     * members to that user team (api call)
     */
    updateTeamMember(member_id) {
      let data = {
        company_id: this.$route.params.id,
        leader_id: this.teamLead.id,
        members: member_id,
      };
      this.$store
        .dispatch("custom/updateTeamMemberforLeader", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.$root.$emit("snack_bar", {
            show: true,
            message: response.data.error_msg,
            snackbarColor: "green",
          });
        });
    },
  },
};
</script>

<style scoped>
.statusIndicators {
  cursor: pointer;
}
.add_team_member {
  margin: unset !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 100% !important;
  overflow: hidden;
}

.assignedProjects div {
  margin-left: 10px;
  float: left;
  margin-top: 2px;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  background-color: palegreen;
}

.unAssignedProjects div {
  margin-left: 10px;
  float: left;
  margin-top: 2px;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  background-color: #36454f;
}

.allProjects div {
  margin-left: 10px;
  float: left;
  margin-top: 2px;
  height: 21px;
  width: 21px;
  border-radius: 3px;
  background-color: #2758f6;
}
</style>